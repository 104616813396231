import React from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { analytics } from "../firebase";
import { PriceFunction } from "../utils";
const Main = styled.a`
	color: black;
	height: 120px;
	border: 5px transparent solid;
	outline: 1px rgb(235, 235, 235) solid;
	margin-bottom: 10px;
	border-radius: 5px;
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05);
	padding: 10px 13px;
	display: flex;
	align-items: center;
	transition: 0.15s ease;
	width: auto;
	margin-left: 5px;
	margin-right: 3px;
	margin-top: 1px;
	width: auto;
	cursor: pointer;
	&:hover {
		background: rgb(235, 235, 235);
		border-left: rgb(78, 140, 244) 5px solid;
		outline: 1px transparent solid;
		color: rgb(78, 140, 244);
		text-decoration: none;
	}
	@media (max-width: 600px) {
		height: fit-content;
		margin: 0px;
		outline: none;
		border-bottom: 1px rgb(235, 235, 235) solid;
		box-shadow: 0px 0px 0px 0px transparent;
		border-radius: 0px;
		border-left: transparent 5px solid;
		&:hover {
			background: rgb(235, 235, 235);
			border-bottom: 1px rgb(235, 235, 235) solid;
			border-left: 5px solid transparent;

			outline: none;
		}
	}
`;

const ImageBLock = styled.div`
	height: 80px;
	width: 100px;
	min-width: 100px;
	border-radius: 8px;
	background: #000 url(${(props) => props.image}) no-repeat;
	background-position: center;
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
	background-size: contain;
	-moz-background-size: cover;
	margin-right: 10px;
	@media (max-width: 400px) {
		height: 60px;
		width: 68px;
		box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
	}
`;

const DescripBLock = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin-left: 10px;
	font-family: "Open Sans";
`;

const PlatformBLock = styled.div`
	font-size: 15px;
	@media (max-width: 600px) {
		font-size: 13px;
	}
	@media (max-width: 350px) {
		font-size: 12px;
	}
`;

const TitleBLock = styled.div`
	display: block; /* Fallback for non-webkit */
	display: -webkit-box;
	font-weight: 700;
	font-size: 17px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 23px;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;

	@media (max-width: 600px) {
		width: 290px;
		font-size: 15px;
		-webkit-line-clamp: 2;
	}
	@media (max-width: 400px) {
		width: 254px;
		font-size: 15px;
	}
	@media (max-width: 350px) {
		width: 180px;
		font-size: 13px;
	}
`;

const Mask = styled.div`
	height: 100%;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.3);
	opacity: 0;
	transition: 0.4s ease;
	&:hover {
		opacity: 1;
	}
	z-index: 1;
`;

const Detail = styled.div`
	font-size: 14px;
	display: flex;
	flex-direction: row;
	@media (max-width: 600px) {
		font-size: 13px;
	}
	@media (max-width: 350px) {
		font-size: 12px;
	}
`;

const PriceBLock = styled.div`
	padding-right: 20px;
	width: fit-content;
`;

const LengthBLock = styled.div``;

export default function SearchBlock({
	Language,
	Source,
	Platform,
	Name,
	Type,
	Price,
	Ratings,
	Tag,
	Link,
	Image,
	Description,
	StoreId,
}) {
	const history = useHistory();
	return (
		<Main
			target="_blank"
			onClick={() => {
				window.open(Link);
				// history.push(`/item/${StoreId}`);
				// analytics.logEvent("enter_platform_from_search", {
				// 	link: Link,
				// 	platform: Platform,
				// 	lang: Language,
				// });
			}}
		>
			<ImageBLock image={Image} />
			<DescripBLock>
				<PlatformBLock>{Platform}</PlatformBLock>
				<TitleBLock>{Name}</TitleBLock>
				<Detail>
					<PriceBLock>{PriceFunction(Price)}</PriceBLock>
					{/* <LengthBLock>Length : {"unknown"}</LengthBLock> */}
				</Detail>
			</DescripBLock>
		</Main>
	);
}
