import React from "react";
import styled from "@emotion/styled";

const Main = styled.div`
	width: 100%;
	height: fit-content;
	height: 200px;
	background-color: #181818;
`;

export default function Bottom() {
	return <Main />;
}
