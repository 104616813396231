import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";

import Survey from "../components/Survey";

const Main = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default function AnimatedSurvey() {
	const [inn, setInn] = useState({
		in1: true,
		in2: false,
		in3: false,
		in4: false,
	});

	return (
		<Main>
			<Survey
				question="Have you ever code before?"
				options={["Yeah", "Nope"]}
				inn={inn.in1}
				onClickFunctionLeft={() => {
					setInn({ ...inn, in1: false, in2: true });
				}}
				onClickFunctionRight={() => {
					setInn({ ...inn, in1: false, in3: true });
				}}
				direction={inn.in1 ? "left" : "right"}
			/>
			<Survey
				question="You are here to..."
				options={["Improve known language", "Learn a new language"]}
				inn={inn.in2}
				onClickFunctionLeft={() => {
					setInn({ ...inn, in2: false });
				}}
				direction={inn.in2 ? "left" : "right"}
			/>
			<Survey
				question="You are here..."
				options={["Just to learn a skill", "To build a project"]}
				inn={inn.in3}
				onClickFunctionLeft={() => {
					setInn({ ...inn, in2: false });
				}}
				direction={inn.in3 ? "left" : "right"}
			/>
		</Main>
	);
}
