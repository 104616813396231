import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import {
	Modal,
	Button,
	Form,
	Alert,
	Col,
	InputGroup,
	Popover,
	OverlayTrigger,
	Row,
	Toast,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import Navbar from "../components/Navbar";
import { useData } from "../contexts/DataContext";
import { useAuth } from "../contexts/AuthContext";
import TuneIcon from "@material-ui/icons/Tune";
import AnimatedSurvey from "../components/AnimatedSurvey";

const Main = styled.div`
	width: 100%;
	height: fit-content;
	padding-top: 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: rgb(243, 243, 243);
`;

const Title = styled.div`
	font-size: 35px;
	width: 100%;
	padding-left: 50px;
	margin-top: 50px;
	margin-bottom: 50px;
	font-weight: 500;
	font-family: "Montserrat", sans-serif;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	@media (max-width: 400px) {
		font-size: 25px;
		padding-left: 30px;
		margin-top: 30px;
	}
	@media (max-width: 500px) {
		font-size: 28px;
		padding-left: 35px;
		margin-top: 40px;
	}
	@media (max-width: 800px) {
		padding-left: 50px;
	}
`;

const Content = styled.div`
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
	background-color: white;
	width: 100%;
	height: 1000px;
`;

const IconContainer = styled.div`
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	right: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.2s ease;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
	@media (max-width: 400px) {
		right: 30px;
	}
	@media (max-width: 500px) {
		right: 35px;
	}
	@media (max-width: 800px) {
		right: 40px;
	}
`;

const StyledTune = styled(TuneIcon)``;

export default function Profile() {
	const { currentUser } = useAuth();

	return (
		<>
			<Main>
				<Content>
					<Navbar />

					<Title>
						Dashboard
						<IconContainer>
							<StyledTune />
						</IconContainer>
					</Title>
					<AnimatedSurvey />
				</Content>
			</Main>
		</>
	);
}
