import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Form, Button, Alert, OverlayTrigger, Popover } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import GoogleButton from "react-google-button";

const RecBlock = styled.div`
	height: fit-content;
	padding-bottom: 30px;
	@media (max-width: 600px) {
		width: 100%;
	}
`;

const Control = styled(Form.Control)`
	width: 400px;
	max-width: 100%;
`;

const Name = styled.div`
	font-weight: 600;
	font-family: "Montserrat", sans-serif;
	font-size: 24px;
	color: #2468f6;
	margin-right: 20px;
	@media (max-width: 430px) {
		font-size: 20px;
	}
	@media (max-width: 400px) {
		font-size: 18px;
		margin: 0px;
	}
`;

const Top = styled.div`
	width: 500px;
	max-width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
	position: relative;
`;
const Logo = styled.img`
	height: 45px;
	margin-right: 10px;
`;

const RecContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 20px;
`;

const StyledForm = styled(Form)`
	margin-left: 20px;
	margin-right: 20px;
`;

const StyledButton = styled(Button)`
	margin-bottom: 20px;
`;

export default function SubmitClass({ setRecShow }) {
	const UrlRef = useRef();
	const whyRef = useRef();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	async function handleSubmit(e) {
		e.preventDefault();
		setError("Fuck Youra adsfasf asdfafd asdf asdfa asdfafda asdfas");
		console.log(e);
	}

	return (
		<RecBlock>
			<Top>
				<Name>Submit Books, Courses, Articles...</Name>
			</Top>

			<StyledForm onSubmit={handleSubmit}>
				<Form.Group controlId="formBasicEmail">
					<Form.Label>🔗 URL</Form.Label>

					<Control
						type="url"
						ref={UrlRef}
						defaultValue="https://"
						autocomplete="off"
						required
					/>
					<Form.Text>
						It can be a link to a book's webpage or one to a huge website. Feel
						free to submit any resources you recommend!
					</Form.Text>
				</Form.Group>

				<Form.Group controlId="formBasicEmail">
					<Form.Label>Why?</Form.Label>

					<Control as="textarea" ref={whyRef} rows={3} />
					<Form.Text>Tell us why this resource is beneficial to you!</Form.Text>
				</Form.Group>

				<RecContainer>
					<StyledButton
						disabled={loading}
						variant="outline-primary"
						type="submit"
					>
						{loading ? "Loading..." : "Submit"}
					</StyledButton>
					{error && (
						<Alert
							variant="danger"
							style={{
								margin: "0",
								maxWidth: "400px",
								height: "fitContent",
							}}
						>
							{error}
						</Alert>
					)}
				</RecContainer>
			</StyledForm>
		</RecBlock>
	);
}
