import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Modal, Button, Form, FormControl } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Navbar from "../components/Navbar";
import Subject from "../components/Subject";
import ClassCard from "../components/ClassCard";
import Bottom from "../components/Bottom";
import { useData } from "../contexts/DataContext";
import SearchBar from "../components/SearchBar";

const Main = styled.div`
	width: 100%;
	height: fit-content;
	padding-top: 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

const Search = styled.div`
	width: 100%;
	height: 180px;
	@media (max-width: 600px) {
		height: 100px;
	}
	border-bottom: 1px rgb(235, 235, 235) solid;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Popular = styled.div`
	position: relative;
	top: 35%;
	@media (max-width: 500px) {
		top: 30%;
	}
	width: 100%;
	padding-left: 20px;
	font-size: 21px;
	font-weight: 500;
	font-family: "Helvetica Neue";
`;

const About = styled.div`
	height: fit-content;
	border: 1px rgb(235, 235, 235) solid;
	background-color: white;
	border: none;
	padding: 10px 30px;
	padding-top: 40px;
	width: 100%;
	max-width: 1100px;
	@media (max-width: 600px) {
		padding: 10px 20px;
		padding-top: 30px;
	}
`;

const Content = styled.div`
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
	background-color: white;
	width: 100%;
	max-width: 1100px;
`;

const Title = styled.div`
	font-size: 30px;
	font-family: "Anton", sans-serif;
	color: black;
`;

const Description = styled.div`
	font-family: "Open Sans", sans-serif;
	line-height: 1.7;
	font-size: 18px;
	margin-top: 10px;
	font-weight: 300;
`;
function Capitalize(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function Home({ params }) {
	const [loginShow, setLoginShow] = useState(false);
	const [signupShow, setSignupShow] = useState(false);
	const { homePage } = useData();
	var sourceType = {};
	const [source, setSource] = useState();
	const { language } = useParams();

	const keys = [
		"Basics",
		"Data Analysis",
		"Web Dev",
		"Games",
		"Machine Learning",
	];

	return (
		<>
			<Main>
				<Content>
					<Navbar setLoginShow={setLoginShow} setSignupShow={setSignupShow} />

					<Search>
						<SearchBar />
					</Search>
					<About>
						<Title>{Capitalize(language)}</Title>
					</About>

					{keys.map((category) => (
						<Subject
							Class={category}
							id={category}
							setSource={setSource}
							source={source}
						></Subject>
					))}
					<Bottom></Bottom>
				</Content>
			</Main>
		</>
	);
}
