import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Main = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
`;

const ForgotPasswordBlock = styled.div`
	width: 500px;
	@media (max-width: 500px) {
		width: 300px;
		border: none;
		bottom: 50px;
		position: relative;
	}
	height: fit-content;
	min-height: 430px;
	background-color: transparent;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	border: 2px rgb(218, 220, 223) solid;
	padding: 20px 10px;
`;

const Control = styled(Form.Control)`
	width: 400px;
	@media (max-width: 500px) {
		width: 280px;
	}
`;

const Name = styled.div`
	font-weight: 650;
	font-family: "Montserrat", sans-serif;
	font-size: 37px;
	color: #2468f6;
	margin-right: 20px;
`;

const Top = styled.div`
	width: 500px;
	max-width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Logo = styled.img`
	height: 45px;
	margin-right: 10px;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
`;

export default function ForgotPassword() {
	const emailRef = useRef();
	const { resetPassword } = useAuth();
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);

	async function handleSubmit(e) {
		e.preventDefault();

		if (!emailRef.current.value) {
			return setError("Email is blank.");
		}

		try {
			setMessage("");
			setError("");
			setLoading(true);
			await resetPassword(emailRef.current.value);
			setMessage("Check your inbox for further instructions!");
		} catch (error) {
			setError(error.message);
		}

		setLoading(false);
	}

	return (
		<Main>
			<ForgotPasswordBlock>
				<Top>
					<Logo src={require("../assets/console-blue.png").default} />
					<Name>ClassConsole</Name>
				</Top>
				<Form onSubmit={handleSubmit}>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Control
							type="email"
							placeholder="Enter email"
							ref={emailRef}
							required
						/>
					</Form.Group>

					<ButtonContainer>
						<Button variant="primary" type="submit" onClick={() => {}}>
							{loading ? "Loading..." : "Reset Password"}
						</Button>
						{error && (
							<Alert
								variant="danger"
								style={{
									margin: "0",
									maxWidth: "250px",
								}}
							>
								{error}
							</Alert>
						)}
						{message && (
							<Alert
								variant="success"
								style={{
									margin: "0",
									maxWidth: "250px",
								}}
							>
								{message}
							</Alert>
						)}
					</ButtonContainer>
					<div style={{ fontSize: "15px", marginTop: "17px" }}>
						<Link to="/home"> Go Back to Home Page</Link>
					</div>
				</Form>
			</ForgotPasswordBlock>
		</Main>
	);
}
