import React, { useContext, useState, useEffect, useRef } from "react";

const DataContext = React.createContext();

export function useData() {
	return useContext(DataContext);
}
// const API = "http://localhost:5000/";
const API = "https://api.flossypork.com/";
function fullURL(x = "/") {
	return (
		(x.indexOf(API.slice(0, -1)) == 0 ? "" : API) +
		(x[0] == "/" ? x.slice(1) : x)
	);
}
function authfetch(url = "/", opt = {}, ...params) {
	return fetch(
		fullURL(url.toString()),
		{ ...opt /*, cache: 'force-cache'*/ },
		...params
	);
}
const camalize = (str) =>
	str.match(/[a-z][a-zA-Z0-9]*/)?.[0] == str
		? str
		: str
				.trim()
				.toLowerCase()
				.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export default function DataProvider({ children, setLoading }) {
	const [filterPreferences, setFilterPreferences] = useState({});
	const [filterResult, setFilterResult] = useState({ Chosen: "Courses" });
	const [cache, setCache] = useState({});
	const items = useRef({});

	function registerItem(item) {
		if(item.StoreId) items.current[item.StoreId] = item;
	}

	function fetchjson(url, fallback = false, err = () => false) {
		url = url.toString();
		if (url in cache) return cache[url];
		setCache({ ...cache, [url]: fallback });
		setLoading(true);
		(async () => {
			try {
				var res = await authfetch(url);
				if (res.ok) setCache({ ...cache, [url]: await res.json() });
				else {
					const set = err(res);
					if(set !== undefined) setCache({ ...cache, [url]: set });
				}
			} catch (e) {
				console.error(e);
				setCache({ ...cache, [url]: false })
			} finally {
				setLoading(false);
			}
		})();
		return fallback;
	}

	useEffect(() => {
		(async () => {
			var res = await fetch(
				"https://firebasestorage.googleapis.com/v0/b/flossypork.appspot.com/o/public%2FfilterPreferences.json?alt=media",
				{
					mode: "cors",
				}
			);
			if (!res.ok) return console.error(res);
			res = await res.json();
			setFilterPreferences(res);
		})();
	}, []);

	function homePage() {
		var res = fetchjson("/list?group=home", false);
		if (res === false) return {};
		const languages = [...new Set(res.results.map((e) => e.Language))];
		res.results.forEach(registerItem);
		return res.results.reduce(
			(acc, cur) => (acc[cur.Language].push(cur), acc),
			Object.fromEntries(languages.map((e) => [e, []]))
		);
	}

	const [searchData, setSearchData] = useState(false);
	function searchPage(keywords, page = 1, history) {
		setSearchData(false);
		const url = new URL(fullURL("/list")),
			options = filterResult[filterResult.Chosen];
		if (!options) return;
		url.searchParams.append("keywords", keywords);
		url.searchParams.append("page", page);
		url.searchParams.append("source", filterResult.Chosen);
		const query = Object.entries(options).reduce((acc, [k, v]) => {
			k = camalize(k);
			if (v === false) return acc;
			if (v === true) return { ...acc, attr: (acc.attr || []).concat(k) };
			if (v != "No Preference") return { ...acc, [k]: v };
			return acc;
		}, {});
		Object.entries(query).forEach((param) => url.searchParams.append(...param));
		// history.push(url.search);
		const res = fetchjson(url);
		res?.results?.forEach?.(registerItem);
		setSearchData(res);
	}

	function getItem(id) {
		if (id in items.current) return items.current[id];
		const res = fetchjson(`/item/${id}`, false, res => res.status === 404 ? 404 : console.error(res.status));
		if (res === false) return false;
		else if (res === 404) return 404;
		else return registerItem(res.result), res.result;
	}

	//Get the filter result from here

	useEffect(() => {
		const keys = Object.keys(filterPreferences);

		keys.forEach((k) =>
			filterPreferences[k].forEach((pref) =>
				setFilterResult((filterResult) =>
					pref.type === "check"
						? {
								...filterResult,
								[k]: { ...filterResult[k], [pref.name]: false },
						  }
						: {
								...filterResult,
								[k]: { ...filterResult[k], [pref.name]: pref.options[0] },
						  }
				)
			)
		);
	}, [filterPreferences]);

	function filterPackage() {
		return { filterResult, setFilterResult, filterPreferences };
	}

	const value = {
		homeData: homePage(),
		searchData,
		searchPage,
		filterPackage,
		setLoading,
		getItem
	};
	return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}
