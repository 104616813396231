import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Modal, Button, Form, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { analytics } from "../firebase";

export default function SearchBar({ setCurrentPage, size }) {
	const history = useHistory();
	const ref = useRef();
	const keywords = new URL(window.location.href).searchParams.get("keywords");

	return (
		<Form
			style={{
				margin: "0 20px",
				marginLeft: "25px",
				maxWidth: "800px",
				width: "80%",
			}}
			inline
			onSubmit={(e) => {
				e.preventDefault();
				analytics.logEvent("searched_keywords");
				if (setCurrentPage) {
					setCurrentPage(1);
				}
				if (ref.current.value) {
					history.push(`/search?keywords=${ref.current.value}`);
				}
			}}
		>
			{size === "lg" ? (
				<FormControl
					style={{
						height: "60px",
						borderRadius: "3px",
						border: "none",
						boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.1)",
						width: "100%",
						padding: "0px 20px",
						fontSize: "19px",
						fontFamily: "Open Sans",
						fontWeight: "400",
					}}
					type="text"
					placeholder="Search for materials from YouTube, Udemy..."
					defaultValue={keywords}
					className="mr-sm-2"
					ref={ref}
				/>
			) : (
				<FormControl
					style={{
						height: "48px",
						borderRadius: "3px",
						border: "none",
						boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.1)",
						width: "100%",
						padding: "0px 15px",
						fontSize: "15px",
						fontFamily: "Open Sans",
						fontWeight: "400",
					}}
					type="text"
					placeholder="Search for materials from YouTube, Udemy..."
					defaultValue={keywords}
					className="mr-sm-2"
					ref={ref}
				/>
			)}
		</Form>
	);
}
