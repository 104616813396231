export function PriceFunction(Price, slice=Infinity){
  function prettify(p) {
    if (p.indexOf(':') == -1) {
      if (p[0] == "$") p = p.slice(1);
      p = parseFloat(p);
      if(isNaN(p)) return "";
      return p === 0 ? "Free" : `$${p}`;
    }
    const [k, v] = p.split(":");
    return `${k} : ${prettify(v)}`;
  }
  if (Price === undefined) return "";
  try {
    const p = Price.toString();
    if (p.length === 0) return "";
    return prettify(p.split(',')[0]);
  } catch(e) { return console.error(e), ""; }
}