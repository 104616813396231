import React, { useState } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import styled from "@emotion/styled";
import { Spinner, Button } from "react-bootstrap";
import AuthProvider from "./contexts/AuthContext";
import DataProvider from "./contexts/DataContext";
import PrivateRoute from "./components/PrivateRoute";

import Home from "./screens/Home";
import ForgotPassword from "./screens/ForgotPassword";
import Profile from "./screens/Profile";
import SubjectPage from "./screens/SubjectPage";
import SearchPage from "./screens/SearchPage";
import Dashboard from "./screens/Dashboard";
import ClassPage from "./screens/ClassPage";

const Center = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 65px;
	width: 100px;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`;

const Loader = () => {
	return (
		<Center>
			<Spinner animation="border"></Spinner>
			<span>&nbsp;&nbsp;Loading...</span>
		</Center>
	);
};

export default function App() {
	var [loading, setLoading] = useState(false);
	// console.log(loading);
	return (
		<AuthProvider>
			<DataProvider setLoading={setLoading}>
				{loading ? (
					<Loader />
				) : (
					<Router>
						<Switch>
							<Route path="/" exact>
								<Redirect to="/home" />
							</Route>
							<Route path="/home" exact component={Home} />
							<Route path="/forgot-password" exact component={ForgotPassword} />
							<Route path="/subject/:language" exact component={SubjectPage} />
							<Route path="/search" exact component={SearchPage} />
							<Route path="/dashboard" exact component={Dashboard} />
							<Route path="/item/:itemId" exact component={ClassPage} />

							<PrivateRoute path="/profile" exact component={Profile} />
						</Switch>
					</Router>
				)}
			</DataProvider>
		</AuthProvider>
	);
}
