import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import {
	Modal,
	Button,
	Form,
	Alert,
	Col,
	InputGroup,
	Popover,
	OverlayTrigger,
	Row,
	Toast,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import Navbar from "../components/Navbar";
import Subject from "../components/Subject";
import ClassCard from "../components/ClassCard";
import Signup from "../components/Signup";
import Bottom from "../components/Bottom";
import { useData } from "../contexts/DataContext";
import { useAuth } from "../contexts/AuthContext";
import ProfilePhoto from "../components/AccountCircle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CancelIcon from "@material-ui/icons/Cancel";

const L = styled.div`
	width: 100%;
	height: fit-content;
	overflow-x: hidden;
`;

const Main = styled.div`
	width: 100%;
	height: fit-content;
	overflow-x: hidden;
	padding-top: 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: rgb(243, 243, 243);
	padding: 0;
`;

const Control = styled(Form.Control)`
	max-width: 800px;
	border-radius: 0px;
	height: 45px;
	font-size: 17px;
	font-weight: 300;
	margin: 0px;
	::placeholder {
		font-size: 15px;
	}
	width: ${(props) => {
		return props.width - 75;
	}}px;
`;

const EmailControl = styled(Form.Control)`
	max-width: 800px;
	border-radius: 0px;
	height: 45px;
	font-size: 17px;
	font-weight: 300;
	margin: 0px;
	::placeholder {
		font-size: 15px;
	}
	width: ${(props) => {
		return props.width - 125;
	}}px;
`;

const Title = styled.div`
	font-size: 35px;
	width: 100%;
	margin-top: 50px;
	margin-bottom: 50px;
	font-weight: 500;
	font-family: "Montserrat", sans-serif;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	font-weight: 600;
	@media (max-width: 400px) {
		font-size: 25px;
		margin-top: 30px;
	}
	@media (max-width: 500px) {
		font-size: 28px;
		margin-top: 40px;
	}
	@media (max-width: 720px) {
		margin-bottom: 20px;
	}
`;

const SignupContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-top: 40px;
	height: fit-content;
`;

const About = styled.div`
	height: fit-content;
	border: 1px rgb(235, 235, 235) solid;
	background-color: rgb(241, 242, 247);
	padding: 20px 25px;
`;

const Content = styled.div`
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
	background-color: white;
	width: 100%;
	z-index: 1;
`;

const Prof = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 10px;
	line-height: 2;
	height: fit-content;
	padding-bottom: 200px;
	overflow-x: hidden;
	width: 100%;
`;

const Photo = styled.span`
	position: relative;
	height: 160px;
	width: 160px;
	margin-bottom: 50px;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 500px) {
		height: 100px;
		width: 100px;
	}
`;

const FormandPhoto = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Image = styled.img`
	width: 150px;
	height: 150px;
	@media (max-width: 500px) {
		height: 100px;
		width: 100px;
	}
`;

const ButtonContainter = styled.div`
	width: 300px;
	height: fit-content;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
`;

export default function Profile() {
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	const nameRef = useRef();
	const {
		currentUser,
		updatePassword,
		updateEmail,
		updateProfile,
		sendEmailVerification,
	} = useAuth();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const [color, setColor] = useState("red");
	const [verifyShow, setVerifyShow] = useState(false);
	const target = useRef(null);
	const [show, setShow] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
		});
		return () =>
			window.removeEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
	}, []);

	const popover = (
		<Popover
			id="popover-basic"
			style={{
				boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.1)",
				border: "none",
				width: "fit-content",
				maxWidth: "230px",
			}}
		>
			<Popover.Content>
				{currentUser.emailVerified
					? "Verified!"
					: "Seems like you haven't verified your email... Click to verify now!"}
			</Popover.Content>
		</Popover>
	);

	function handleSubmit(e) {
		e.preventDefault();

		var words = nameRef.current.value.split(" ");
		if (words.length > 3) {
			return setError("User Name is limited to 3 words");
		}
		for (var i = 0; i < words.length; i++) {
			if (words[i].length > 16) {
				return setError("Every word in User Name is limited to 16 characters");
			}
		}

		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Passwords do not match");
		}

		const promises = [];
		setLoading(true);
		setError("");

		if (emailRef.current.value !== currentUser.email) {
			promises.push(updateEmail(emailRef.current.value));
		}
		if (passwordRef.current.value) {
			promises.push(updatePassword(passwordRef.current.value));
		}
		if (nameRef.current.value) {
			promises.push(updateProfile(nameRef.current.value));
		}

		Promise.all(promises)
			.then(() => {})
			.catch((e) => {
				setError(e.message);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function handleSendVerification(e) {
		e.preventDefault();
		if (!currentUser.emailVerified) {
			setColor("#ef7564");
			sendEmailVerification()
				.then(() => {
					setShow(true);
					setColor("red");
				})
				.catch();
		}
	}

	return (
		<L>
			<Main>
				<Content>
					<Navbar />

					<Prof>
						<FormandPhoto>
							<Title>Profile</Title>
							<Photo>
								{currentUser?.photoURL ? (
									<ProfilePhoto height={width > 720 ? 150 : 100} />
								) : (
									<Image src={require("../assets/user.png").default} />
								)}
							</Photo>
							<Form onSubmit={handleSubmit} className="align-items-center">
								<Form.Group>
									<Form.Row className="align-items-center" lg={2}>
										<Col>
											<Control
												type="text"
												ref={nameRef}
												required
												defaultValue={currentUser.displayName}
												placeholder="🤟 User Name"
												width={width}
											/>
										</Col>
									</Form.Row>
									<br></br>
									<Form.Row className="align-items-center" lg={2}>
										<Col>
											<InputGroup hasValidation>
												<OverlayTrigger
													trigger="hover"
													placement={
														currentUser?.emailVerified ? "left" : "top"
													}
													overlay={popover}
												>
													<InputGroup.Prepend>
														<InputGroup.Text
															id="inputGroupPrepend"
															onClick={handleSendVerification}
														>
															{currentUser?.emailVerified ? (
																<VerifiedUserIcon style={{ color: "green" }} />
															) : (
																<CancelIcon style={{ color: `${color}` }} />
															)}
														</InputGroup.Text>
													</InputGroup.Prepend>
												</OverlayTrigger>

												<EmailControl
													type="email"
													ref={emailRef}
													required
													defaultValue={currentUser.email}
													placeholder="📨 Email address"
													width={width}
												/>
											</InputGroup>
										</Col>
									</Form.Row>
									<br></br>
									<Form.Row className="align-items-center" lg={2}>
										<Col>
											<Control
												type="password"
												sytle={{ fontSize: "15px" }}
												placeholder="Password (Leave blank to keep the same)"
												ref={passwordRef}
												width={width}
											/>
										</Col>
									</Form.Row>
									<br></br>
									<Form.Row className="align-items-center" lg={2}>
										<Col>
											<Control
												type="password"
												placeholder="Password Confirmation"
												ref={passwordConfirmRef}
												width={width}
											/>
										</Col>
									</Form.Row>
									{/* <Form.File
										id="custom-file"
										label="Custom file input"
										custom
									/> */}
								</Form.Group>

								<SignupContainer>
									{error && (
										<Alert
											variant="danger"
											style={{
												margin: "0",
												width: "fit-content",
												maxWidth: `${width - 75}px`,
												fontSize: "15px",
												marginBottom: "20px",
											}}
										>
											{error}
										</Alert>
									)}
									<ButtonContainter>
										<Button
											disabled={loading}
											variant="outline-primary"
											type="submit"
										>
											{loading ? "Loading..." : "Save Changes"}
										</Button>
										<Button
											disabled={loading}
											variant="outline-danger"
											type="reset"
											onClick={() => {
												setError("");
											}}
										>
											Discard Changes
										</Button>
									</ButtonContainter>
								</SignupContainer>
							</Form>
						</FormandPhoto>
					</Prof>
				</Content>
			</Main>
			<Row
				style={{
					backgroundColor: "rgb(243, 243, 243)",
					paddingLeft: "40px",
					paddingTop: "10px",
				}}
			>
				<Col xs={6}>
					<Toast
						onClose={() => setShow(false)}
						show={show}
						style={{ backgroundColor: "white" }}
					>
						<Toast.Header>
							<strong className="mr-auto">Flossy Pork</strong>
							<small>Just Now</small>
						</Toast.Header>
						<Toast.Body>Email Verification Sent. Check Your Inbox!</Toast.Body>
					</Toast>
				</Col>
			</Row>
		</L>
	);
}
