import React from "react";
import styled from "@emotion/styled";
import { Modal, Button } from "react-bootstrap";
import Slide from "@material-ui/core/Slide";

const Main = styled.div`
	width: 600px;
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
`;

const Question = styled.div`
	font-size: 28px;
	font-weight: 500;
	font-family: "Montserrat", sans-serif;
	margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
	font-size: 19px;
	font-family: "Montserrat", sans-serif;
	margin: 0px 5px;
`;

const Buttons = styled.div`
	display: flex;
	width: 500px;
	justify-content: space-evenly;
	align-items: center;
`;

const questions = [
	{ question: "Have you ever code before?", options: ["Yeah", "Nope"] },
	{
		question: "Why do you want to learn coding?",
		options: ["Just to gain a skill", "Build a project"],
	},
	{ question: "Which languages are you confident in?", options: [] },
];

export default function Survey({
	question,
	options,
	inn,
	onClickFunctionLeft,
	onClickFunctionRight,
	direction,
}) {
	return (
		<Slide direction={direction} in={inn} mountOnEnter unmountOnExit>
			<Main>
				<Question>{question}</Question>
				<Buttons>
					{options.length <= 2 && (
						<>
							<StyledButton
								variant="outline-success"
								onClick={onClickFunctionLeft}
							>
								{options[0]}
							</StyledButton>
							<StyledButton
								variant="outline-success"
								onClick={onClickFunctionRight}
							>
								{options[1]}
							</StyledButton>
						</>
					)}
				</Buttons>
			</Main>
		</Slide>
	);
}
