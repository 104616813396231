import React from "react";
import styled from "@emotion/styled";

import { useAuth } from "../contexts/AuthContext";

export default function AccountCircle({
	height,
	setUserDrop,
	color,
	hoverColor,
	userDrop,
	marginRight,
}) {
	const { currentUser } = useAuth();

	const ProfilePic = styled.img`
		border-radius: 50%;
		height: ${height}px;
		width: ${height}px;
		object-fit: cover;
		margin-right: ${marginRight}px;
	`;

	const Icon = styled.img`
		height: ${height}px;
		width: ${height}px;
		margin-right: ${marginRight}px;
	`;

	if (setUserDrop) {
		if (currentUser?.photoURL) {
			return (
				<ProfilePic
					src={currentUser?.photoURL}
					onMouseEnter={() => {
						setUserDrop(true);
					}}
					onClick={() => {
						setUserDrop(true);
					}}
				/>
			);
		} else {
			return (
				<Icon
					onMouseEnter={() => {
						setUserDrop(true);
					}}
					onClick={() => {
						setUserDrop(true);
					}}
					src={require("../assets/user.png").default}
				/>
			);
		}
	} else {
		if (currentUser?.photoURL) {
			return <ProfilePic src={currentUser.photoURL} />;
		} else {
			return <Icon src={require("../assets/user.png").default} />;
		}
	}
}
