import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled/macro";
import {
	Modal,
	Button,
	Form,
	FormControl,
	Tabs,
	Tab,
	Row,
	Col,
	Pagination,
} from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";

import Navbar from "../components/Navbar";
import ClassCard from "../components/ClassCard";
import Bottom from "../components/Bottom";
import { useData } from "../contexts/DataContext";
import SearchBar from "../components/SearchBar";
import SearchBlock from "../components/SearchBlock";

const L = styled.div`
	overflow-y: ${(props) => (props.show ? "hidden" : "scroll")};
	&::-webkit-scrollbar {
		display: none;
	}
	height: 100vh;
	background-color: rgb(243, 243, 243);
`;

const Main = styled.div`
	width: 100%;
	height: fit-content;
	padding-top: 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

const Search = styled.div`
	width: 100%;
	height: 200px;
	border-bottom: 1px rgb(235, 235, 235) solid;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 20px;
	@media (max-width: 600px) {
		height: 170px;
	}
`;

const Little = styled.div`
	font-size: 15px;
	width: 100%;
	position: relative;
	top: 48px;
	padding-left: 30px;
	font-weight: 300;
	font-family: "Open Sans";
	display: flex;
	padding-right: 30px;
	justify-content: space-between;
	@media (max-width: 600px) {
		font-size: 13px;
		top: 45px;
		padding-left: 15px;
		padding-right: 7px;
	}
`;

const FilterIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	&:hover {
		background-color: rgb(235, 235, 235);
	}
	display: none;
	width: 40px;
	height: 40px;
	position: relative;
	bottom: 10px;
	@media (max-width: 1040px) {
		display: flex;
	}
`;

const Content = styled.div`
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
	background-color: white;
	width: 100%;
`;

const FilterAndSearch = styled.div`
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 50px;
	padding-bottom: 50px;
	@media (max-width: 1040px) {
		padding-left: 20px;
		padding-right: 20px;
	}
	@media (max-width: 600px) {
		padding-left: 0px;
		padding-right: 0px;
		padding-top: 0px;
	}
`;

const Filter = styled.div`
	border: 1px rgb(235, 235, 235) solid;
	margin-right: 20px;
	box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
	padding: 30px;
	font-family: "Open Sans";
	font-weight: 600;
	font-size: 16px;
	padding-right: 0px;
	height: fit-content;
	width: 510px;
	padding-left: 20px;
	@media (max-width: 1040px) {
		display: none;
	}
`;

const SearchResults = styled.div`
	width: 100%;
	height: fit-content;
	overflow-x: hidden;
	overflow-y: hidden;
`;

const Pag = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-bottom: 50px;
`;

function Capitalize(str) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function Home({ params }) {
	// useEffect(() => {
	// 	console.log('init');
	// }, []);

	const [loginShow, setLoginShow] = useState(false);
	const [signupShow, setSignupShow] = useState(false);
	const { searchData, searchPage, filterPackage } = useData();
	const { filterResult, setFilterResult, filterPreferences } = filterPackage();
	var sourceType = {};
	const [source, setSource] = useState();
	const history = useHistory();
	const [show, setShow] = useState(false);
	const url = new URL(window.location.href);
	var currentPage = Number(url.searchParams.get("page")) || 1;
	const totalNumberOfPage = searchData?.pages || 0;
	const setCurrentPage = (x) => {
		url.searchParams.set("page", x);
		history.push(url.toString().slice(url.origin.length));
	};
	const [update, setUpdate] = useState(true);

	function updateData() {
		searchPage(url.searchParams.get("keywords"), currentPage, history);
	}

	useEffect(updateData, [window.location.href, currentPage]);
	useEffect(() => {
		if (!update) return setUpdate(true);
		updateData();
	}, [filterResult]);

	const handleFormOnChange = (type, e, upd = true) => {
		setFilterResult((filterResult) =>
			filterPreferences[type][parseInt(e.target.id.split("/-/")[2])].type ===
			"check"
				? {
						...filterResult,
						[type]: {
							...filterResult[type],
							[e.target.id.split("/-/")[1]]: !filterResult[type][
								e.target.id.split("/-/")[1]
							],
						},
				  }
				: {
						...filterResult,
						[type]: {
							...filterResult[type],
							[e.target.id.split("/-/")[1]]: e.target.value,
						},
				  }
		);
		setCurrentPage(1);
		if (!upd) setUpdate(false);
	};

	const keys = Object.keys(filterPreferences);

	var createPageIds = [];
	for (
		var i = currentPage - 2;
		i <= Math.min(currentPage + 2, totalNumberOfPage);
		i++
	) {
		if (i > 0) {
			createPageIds.push(i);
		}
	}

	return (
		<L>
			<Main show={show} id="searchpageTop">
				<Content>
					<Navbar setLoginShow={setLoginShow} setSignupShow={setSignupShow} />

					<Search>
						<SearchBar setCurrentPage={setCurrentPage} />
						<Little>
							According to your situation, we found...{" "}
							<FilterIcon
								onClick={() => {
									setShow(true);
								}}
							>
								<FilterListRoundedIcon />
							</FilterIcon>
						</Little>
					</Search>
					{searchData ? (
						<>
							<FilterAndSearch>
								<Filter>
									&nbsp;Choose your style !
									<Tabs
										defaultActiveKey={filterResult.Chosen}
										id="filter-material-source"
										style={{
											marginTop: "13px",
											fontSize: "15px",
											fontWeight: "500",
											padding: "0px",
										}}
										onSelect={(k) =>
											setFilterResult({ ...filterResult, Chosen: k })
										}
									>
										{keys.map((type) => {
											var k = -1;

											return (
												<Tab eventKey={type} title={type}>
													<Form
														style={{
															marginTop: "18px",
															fontWeight: "500",
															fontSize: "15px",
															padding: "0px",
															marginLeft: "20px",
														}}
														onChange={(e) => {
															// console.log('big');
															return handleFormOnChange(type, e, true);
														}}
													>
														<div key="custom-checkbox" className="mb-3">
															{filterPreferences[type].map((pref) => {
																if (pref.type === "check") {
																	k += 1;

																	return (
																		<Form.Check
																			custom
																			type="checkbox"
																			id={`${type}/-/${pref.name}/-/${k}`}
																			defaultChecked={
																				filterResult[type] &&
																				filterResult[type][pref.name]
																			}
																			label={pref.name}
																			style={{
																				marginTop: "10px",
																			}}
																		/>
																	);
																}
															})}
														</div>
														{filterPreferences[type].map((pref) => {
															if (pref.type === "select") {
																k += 1;
																return (
																	<Form.Group as={Row} id={pref.name}>
																		<Form.Label
																			column
																			lg={1}
																			style={{
																				width: "200px",
																				marginRight: "15px",
																			}}
																		>
																			{pref.name}
																		</Form.Label>

																		<Col>
																			<Form.Control
																				as="select"
																				defaultValue={pref.options[0]}
																				style={{
																					width: "160px",
																					marginLeft: "40px",
																					fontWeight: "500",
																					fontSize: "15px",
																					padding: "0px 4px",
																					marginBottom: "18px",
																				}}
																				id={`${type}/-/${pref.name}/-/${k}`}
																			>
																				{pref.options.map((o) => {
																					return <option value={o}>{o}</option>;
																				})}
																			</Form.Control>
																		</Col>
																	</Form.Group>
																);
															}
														})}
													</Form>
												</Tab>
											);
										})}
									</Tabs>
								</Filter>
								<SearchResults>
									{searchData.results.map((e) => (
										<SearchBlock {...e} />
									))}
								</SearchResults>
							</FilterAndSearch>
							<Pag>
								<Pagination>
									{currentPage > 1 ? (
										<Pagination.Prev
											onClick={() => {
												setCurrentPage(currentPage - 1);
											}}
											href="#searchpageTop"
										/>
									) : (
										<Pagination.Prev disabled />
									)}
									{createPageIds.map((e) => {
										return (
											<Pagination.Item
												active={currentPage == e}
												onClick={() => {
													setCurrentPage(e);
												}}
												href="#searchpageTop"
											>
												{e}
											</Pagination.Item>
										);
									})}

									{currentPage < totalNumberOfPage ? (
										<Pagination.Next
											onClick={() => {
												setCurrentPage(currentPage + 1);
											}}
											href="#searchpageTop"
										/>
									) : (
										<Pagination.Next disabled />
									)}
								</Pagination>
							</Pag>
						</>
					) : (
						""
					)}

					<Bottom></Bottom>
				</Content>
			</Main>
			<Modal
				show={show}
				onHide={() => {
					setShow(false);
				}}
				centered
			>
				<Modal.Body>
					<Tabs
						defaultActiveKey={filterResult.Chosen}
						id="filter-material-source-small"
						style={{
							marginTop: "13px",
							fontSize: "14px",
							fontWeight: "500",
							padding: "0px",
							paddingLeft: "2%",
						}}
						onSelect={(k) => {
							setFilterResult({ ...filterResult, Chosen: k });
							setUpdate(false);
						}}
					>
						{keys.map((type) => {
							var k = -1;

							return (
								<Tab eventKey={type} title={type} style={{ marginLeft: "10%" }}>
									<Form
										style={{
											marginTop: "18px",
											fontWeight: "500",
											fontSize: "15px",
											paddingTop: "3px",
										}}
										onChange={(e) => {
											// console.log('small');
											return handleFormOnChange(type, e, false);
										}}
									>
										<div key="custom-checkbox" className="mb-3">
											{filterPreferences[type].map((pref) => {
												if (pref.type === "check") {
													k += 1;

													return (
														<Form.Check
															custom
															type="checkbox"
															id={`${type}/-/${pref.name}/-/${k}/-/small`}
															defaultChecked={
																filterResult[type] &&
																filterResult[type][pref.name]
															}
															label={pref.name}
															style={{
																marginTop: "10px",
															}}
														/>
													);
												}
											})}
										</div>
										{filterPreferences[type].map((pref) => {
											if (pref.type === "select") {
												k += 1;
												return (
													<Form.Group as={Row} id={pref.name + "-small"}>
														<Form.Label
															column
															lg={1}
															style={{
																width: "90px",
															}}
														>
															{pref.name}
														</Form.Label>

														<Col>
															<Form.Control
																as="select"
																defaultValue={pref.options[0]}
																style={{
																	width: "140px",
																	marginLeft: "15%",
																	fontWeight: "500",
																	fontSize: "15px",
																	padding: "0px 4px",
																	marginBottom: "8px",
																}}
																id={`${type}/-/${pref.name}/-/${k}/-/small`}
															>
																{pref.options.map((o) => {
																	return <option value={o}>{o}</option>;
																})}
															</Form.Control>
														</Col>
													</Form.Group>
												);
											}
										})}
									</Form>
								</Tab>
							);
						})}
					</Tabs>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							updateData();
							setShow(false);
						}}
						type="submit"
					>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</L>
	);
}
