import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { Tabs, Tab } from "react-bootstrap";
import ClassCard from "./ClassCard";

const Main = styled.div`
	width: 100%;

	height: 350px;
	background-color: white;
	z-index: 1;
	display: flex;
	flex-direction: column;
	padding-left: 0px;
	padding-top: 20px;
	align-items: flex-start;
	justify-content: flex-start;
	border-bottom: 1px rgb(235, 235, 235) solid;
	padding-bottom: 5px;
	overflow-x: hidden;
	@media (max-width: 600px) {
		padding-bottom: 10px;
		height: 340px;
		padding-left: 0px;
	}
`;

const Name = styled.div`
	width: fit-content;
	@media (max-width: 600px) {
		width: 80px;
	}
`;

const ClassName = styled.div`
	font-size: 22px;
	font-weight: 500;
	font-family: "Helvetica Neue";
	margin: 10px;

	margin-top: 0px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 40px;
	padding-bottom: 20px;
	padding-left: 40px;

	@media (max-width: 600px) {
		margin-bottom: 10px;
		padding-right: 20px;
		font-size: 18px;
		padding-left: 10px;
	}
`;

const CardsScroll = styled.div`
	width: fit-content;
	height: 230px;
	display: flex;
	flex-direction: row;
	padding-left: 50px;
	ovrflow-y: hidden;
	@media (max-width: 600px) {
		padding-left: 20px;
	}
`;

const Cards = styled.div`
	display: flex;
	flex: row;
	width: 100%;
	overflow-y: hidden;
	justify-content: flex-start;
	align-items: center;
	overflow-x: scroll;
	margin-top: 5px;
	&::-webkit-scrollbar {
		display: none;
	}
`;

const Options = styled.div`
	position: relative;
	left: 30px;
	font-size: 15px;
	font-weight: 400;
	padding-right: 30px;
	@media (max-width: 600px) {
		font-size: 13px;
	}
`;

export default function Subject({ id, Class, children, setSource, source }) {
	return (
		<Main id={id}>
			<ClassName>
				<Name>{Class}</Name>

				<Options>
					<Tabs
						defaultActiveKey="Online Course"
						id="materials-source"
						onSelect={(k) => {
							var newSource = { ...source };
							newSource[id] = k;
							setSource(newSource);
							console.log(source);
						}}
					>
						<Tab eventKey="Online Course" title="Courses"></Tab>
						<Tab eventKey="Book" title="Books"></Tab>
						{/* <Tab eventKey="Documentation" title="Docs"></Tab> */}
						<Tab eventKey="Article" title="Article"></Tab>
					</Tabs>
				</Options>
			</ClassName>

			<Cards>
				<CardsScroll>{children}</CardsScroll>
			</Cards>
		</Main>
	);
}
