import React, { useContext, useState, useEffect } from "react";
import { auth, googleAuthProvider, analytics } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState();
	const [loading, setLoading] = useState(true);
	function signup(email, password) {
		auth.createUserWithEmailAndPassword(email, password);
		return analytics.logEvent("new_user_sign_up", null);
	}
	function sendEmailVerification() {
		return currentUser.sendEmailVerification();
	}
	function login(email, password) {
		auth.signInWithEmailAndPassword(email, password);
		return analytics.logEvent("user_logged_in", null);
	}
	function logout() {
		return auth.signOut();
	}
	function googleSignIn() {
		auth.signInWithPopup(googleAuthProvider);
		return analytics.logEvent("user_logged_in", null);
	}
	function resetPassword(email) {
		return auth.sendPasswordResetEmail(email);
	}
	function updateEmail(email) {
		return currentUser.updateEmail(email);
	}
	function updatePassword(password) {
		return currentUser.updatePassword(password);
	}
	function updateProfile(name) {
		return currentUser.updateProfile({ displayName: name });
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setCurrentUser(user);
			setLoading(false);
		});
		return unsubscribe;
	}, []);

	const value = {
		currentUser,
		login,
		signup,
		logout,
		resetPassword,
		updateEmail,
		updatePassword,
		loading,
		googleSignIn,
		updateProfile,
		sendEmailVerification,
	};
	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
}
