import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import GoogleButton from "react-google-button";

const SignupBlock = styled.div`
	height: fit-content;
	padding-bottom: 30px;
	@media (max-width: 600px) {
		width: 100%;
	}
`;

const Control = styled(Form.Control)`
	width: 400px;
	max-width: 100%;
`;

const GoogleButtonContainer = styled.div`
	margin-top: 20px;
	margin-bottom: 0px;
`;

const Name = styled.div`
	font-weight: 650;
	font-family: "Montserrat", sans-serif;
	font-size: 37px;
	color: #2468f6;
	margin-right: 20px;
`;

const Top = styled.div`
	width: 500px;
	max-width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 20px;
	position: relative;
`;
const Logo = styled.img`
	height: 45px;
	margin-right: 10px;
`;

const SignupContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
`;

const StyledForm = styled(Form)`
	margin-left: 20px;
	margin-right: 20px;
`;

const StyledButton = styled(Button)`
	font-size: 15px;
	margin-left: 10px;
	padding: 0;
`;

const Middle = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export default function Signup({ setSignupShow, setLoginShow }) {
	const emailRef = useRef();
	const passwordRef = useRef();
	const passwordConfirmRef = useRef();
	const { currentUser, signup, googleSignIn } = useAuth();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	async function handleSubmit(e) {
		e.preventDefault();
		if (passwordRef.current.value !== passwordConfirmRef.current.value) {
			return setError("Passwords do not match");
		}

		try {
			setError("");
			setLoading(true);
			await signup(emailRef.current.value, passwordRef.current.value);
			history.push("/");
		} catch (e) {
			setError(e.message);
		}
		setLoading(false);
	}

	async function handleGoogleLogin(e) {
		e.preventDefault();
		try {
			setError("");
			await googleSignIn();
			setLoginShow(false);
			history.push("/");
		} catch (error) {
			setLoginShow(false);
			setError(error.message);
			console.log(error);
		}
	}

	return (
		<SignupBlock>
			<Top>
				<Logo src={require("../assets/console-blue.png").default} />
				<Name>ClassConsole</Name>
			</Top>

			<StyledForm onSubmit={handleSubmit}>
				<Form.Group controlId="formBasicEmail">
					<Form.Label>Email address</Form.Label>
					<Control
						type="email"
						placeholder="Enter email"
						ref={emailRef}
						required
					/>
				</Form.Group>

				<Form.Group controlId="formBasicPassword">
					<Form.Label>Password</Form.Label>
					<Control
						type="password"
						placeholder="Password"
						ref={passwordRef}
						required
					/>
				</Form.Group>
				<Form.Group controlId="formBasicPassword">
					<Form.Label>Password Confirmation</Form.Label>
					<Control
						type="password"
						placeholder="Confirm Password"
						ref={passwordConfirmRef}
						required
					/>
				</Form.Group>
				<GoogleButtonContainer>
					<GoogleButton
						onClick={handleGoogleLogin}
						type="dark"
						label="Sign Up with Google"
						style={{ width: "190px", fontSize: "13px" }}
					/>
				</GoogleButtonContainer>
				<SignupContainer>
					<Button disabled={loading} variant="primary" type="submit">
						{loading ? "Loading..." : "Sign Up"}
					</Button>
					{error && (
						<Alert variant="danger" style={{ margin: "0", maxWidth: "300px" }}>
							{error}
						</Alert>
					)}
				</SignupContainer>

				<Middle style={{ fontSize: "15px", marginTop: "10px" }}>
					Already got an account ?{" "}
					<StyledButton
						variant="link"
						onClick={() => {
							setLoginShow(true);
							setSignupShow(false);
						}}
					>
						Login
					</StyledButton>
				</Middle>
			</StyledForm>
		</SignupBlock>
	);
}
