import React from "react";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { analytics } from "../firebase";
import { PriceFunction } from "../utils";

const Main = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: fit-content;
	font-weight: 500;
	&:hover {
		text-decoration: underline;
	}
`;

const Card = styled.div`
	height: 141px;
	background-color: white;
	width: 250px;
	z-index: 10;
	margin-right: 20px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-size: ${(props) => {
		return props.source === "Article" ? "cover" : "contain";
	}};

	display: flex;
	justify-content: center;
`;

const Mask = styled.div`
	width: ${(props) => {
		return props.source === "Book" || props.platform === "YouTube"
			? "0px"
			: "248px";
	}};
	height: 141px;
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.3);
	color: #ffffff;
	opacity: 0;
	transition: 0.4s ease;
	&:hover {
		opacity: 1;
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: fit-content;
	width: 250px;
	padding: 0px 5px;
	padding-top: 5px;
`;

const NameDiv = styled.div`
	height: 50px;
	width: 250px;
	font-weight: 500;
	font-size: 15px;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	line-height: 23px;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
`;

const Item = styled.div`
	font-weight: 500;
	font-size: 15px;
	text-decoration: none;
	cursor: pointer;
`;

export default function ClassCard({
	Source,
	Platform,
	Price,
	Link,
	Name,
	Image,
	Language,
	StoreId,
}) {
	const history = useHistory();
	if (Price && Price[0] !== "$" && Platform !== "Amazon") {
		Price = "$" + Price;
	}
	if (Platform === "Amazon") {
		Price = Price.split(",")[0];
		Price = Price.split(":").join(" : $");
	}
	return (
		<Main>
			<NameDiv>{Name.replace(/&quot;/g, '"')}</NameDiv>

			<Card
				style={{ backgroundImage: `url(${Image})` }}
				onClick={() => {
					window.open(Link);
					// history.push(`/item/${StoreId}`);
					// console.log(Link);
					// analytics.logEvent("enter_platform_from_home", {
					// 	link: Link,
					// 	platform: Platform,
					// 	lang: Language,
					// });
				}}
				source={Source}
			>
				<Mask source={Source} platform={Platform} />
			</Card>

			<Info>
				<Item>{Platform}</Item>

				<Item>{PriceFunction(Price, 1)}</Item>
				{/*<Item>Teacher</Item>*/}
			</Info>
		</Main>
	);
}
