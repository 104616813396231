import React, { useState, useRef } from "react";
import styled from "@emotion/styled/macro";
import { Button, Form, FormControl, Media, Modal } from "react-bootstrap";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useHistory } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";
import { useData } from "../contexts/DataContext";
import AccountCircle from "../components/AccountCircle";
import Login from "../components/Login";
import Signup from "../components/Signup";
import SubmitClass from "../components/SubmitClass";

const Main = styled.div`
	width: 100%;
	border-bottom: 1px rgb(235, 235, 235) solid;
	background-color: white;
	padding: 0 38px;
	height: 80px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 470px) {
		padding: 0 20px;
	}
	@media (max-width: 400px) {
		padding: 0 20px;
	}
	@media (max-width: 390px) {
		padding-right: 10px;
	}
	@media (max-width: 300px) {
		padding: 0 12px;
	}
`;

const StyledMenuIcon = styled(MenuIcon)`
	color: ${(props) => {
		return props.customcolor;
	}};
	@media (max-width: 470px) {
		display: none !important;
	}
`;

const DropdownOuter = styled.div`
	width: 200px;
	height: fit-content;
	position: absolute;
	top: 80px;
	left: ${(props) => {
		return props.x;
	}}px;
	padding-top: 5px;
	z-index: 30;
`;

const DropdownItem = styled.a`
	background-color: white;
	text-decoration: none;
	outline: none;
	border: none;
	width: 220px;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 5px 10px;
	color: rgb(74, 74, 70);
	font-size: 15px;
	font-weight: 300;
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
		color: black;
		text-decoration: none;
	}
	cursor: pointer;
`;

const Dropdown = styled.div`
	background-color: white;
	width: fit-content;
	height: fit-content;
	z-index: 20;
	border-radius: 3px;
	box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	cursor: pointer;
	& ${DropdownItem}:first-of-type {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		padding: 15px 10px;
	}
	& ${DropdownItem}:last-of-type {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		padding: 15px 10px;
	}
`;

const Name = styled.div`
	font-weight: 650;
	font-family: "Montserrat", sans-serif;
	font-size: 35px;
	color: #2468f6;
	line-height: 30px;
	/* margin-right: 10px; */
	cursor: pointer;
	@media (max-width: 600px) {
		font-size: 28px;
	}
	@media (max-width: 470px) {
		font-size: 23px;
		font-weight: 550;
		margin-right: 0px;
	}
	@media (max-width: 350px) {
		font-size: 18px;
		font-weight: 500;
	}
	@media (max-width: 300px) {
		font-size: 18px;
		line-height: 20px;
	}
`;

const NameLogo = styled.div`
	display: flex;
	justify-content: flex-start;
	width: fit-content;
	align-items: center;
	position: relative;
	left: 0px;
	/* @media (max-width: 870px) {
		left: 30px;
	}
	@media (max-width: 660px) {
		left: 10px;
	}
	@media (max-width: 500px) {
		left: 10px;
	}
	@media (max-width: 470px) {
		left: 0px;
	} */

	/* @media (max-width: 395px) {
		left: 40px;
	}
	@media (max-width: 375px) {
		left: 40px;
	}
	@media (max-width: 372px) {
		left: 65px;
	}
	@media (max-width: 320px) {
		left: 45px;
	}
	@media (max-width: 300px) {
		left: 50px;
	} */
`;

const Logo = styled.img`
	margin-right: 10px;
	height: 40px;
	@media (max-width: 600px) {
		height: 30px;
	}
	@media (max-width: 500px) {
		height: 30px;
	}
	@media (max-width: 470px) {
		height: 28px;
	}
	@media (max-width: 350px) {
		height: 25px;
	}
	@media (max-width: 300px) {
		margin-right: 5px;
	}
`;

const StyledButton = styled(Button)`
	margin: 0;
	color: #2468f6;
	&:hover {
		color: #2455f6;
	}
	&:active {
		outline: none;
		text-decoration: none;
	}
	/* @media (max-width: 720px) {
		padding-right: 0px;
		padding-left: 0px;
	} */
	/* @media (max-width: 630px) {
		border-right: -15px;
	} */
`;

const Buttons = styled.div`
	position: relative;
	left: 0px;
	& ${StyledButton}:last-of-type {
		@media (max-width: 660px) {
			/* display: none; */
			padding-left: 0px;
		}
		@media (max-width: 370px) {
			display: none;
		}
	}
	& ${StyledButton}:first-of-type {
		@media (max-width: 870px) {
			display: none;
			padding-left: 200px;
		}
	}
`;

const User = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 50px;
	padding-right: 0;
	@media (max-width: 380px) {
		margin-left: 6px;
	}
`;

const UserDropdownOuter = styled.div`
	width: fit-content;
	height: fit-content;
	position: absolute;
	top: 57px;
	right: 0px;
	padding-right: 0px;
	padding-top: 8px;
	z-index: 30;
	background-color: transparent;
	z-index: 99;
`;

const UserDropdownItem = styled(Link)`
	background-color: white;
	text-decoration: none;
	outline: none;
	border: none;
	width: 220px;
	height: 48px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;
	color: black;
	font-size: 15px;
	font-weight: 300;
	color: rgb(74, 74, 70);
	z-index: 101;
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
		color: black;
		text-decoration: none;
	}
	cursor: pointer;
`;

const UserDropdown = styled.div`
	background-color: white;
	width: fit-content;
	height: fit-content;
	z-index: 20;
	border-radius: 3px;
	box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	z-index: 100;
	cursor: pointer;
	& ${UserDropdownItem}:first-of-type {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		padding: 15px 10px;
		height: 90px;
		font-weight: 500;
		border-bottom: rgb(207, 212, 217) 1px solid;
		&:hover {
			background-color: transparent;
		}
	}
	& ${UserDropdownItem}:last-of-type {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		padding: 15px 10px;
	}
`;

export default function Navbar() {
	const [drop, setDrop] = useState(false);
	const [userDrop, setUserDrop] = useState(false);
	const [menuColor, setMenuColor] = useState("#2468f6");
	const [loginShow, setLoginShow] = useState(false);
	const [signupShow, setSignupShow] = useState(false);
	const [recShow, setRecShow] = useState(false);
	const menuRef = useRef();
	const userRef = useRef();
	const { currentUser, logout, loading } = useAuth();
	const history = useHistory();
	const { homeData } = useData();
	const keys = Object.keys(homeData);
	// currentUser.updateProfile({
	// 	displayName: "Jerry Chang",
	// 	photoURL:
	// 		"https://www.pandasecurity.com/en/mediacenter/src/uploads/2013/11/pandasecurity-facebook-photo-privacy.jpg",
	// });

	async function handleLogout() {
		try {
			await logout();
			setUserDrop(false);
			history.push("/");
		} catch (e) {
			console.log(e.message);
		}
	}

	return (
		<>
			<Main
				onMouseLeave={() => {
					setDrop(false);
					setUserDrop(false);
					setMenuColor("#2468f6");
				}}
			>
				{/* <StyledMenuIcon
					onMouseEnter={() => {
						setDrop(true);
						setMenuColor("#2455f6");
					}}
					onClick={() => {
						setDrop(true);
					}}
					customcolor={menuColor}
					ref={menuRef}
				/> */}
				{/* 
				{drop && (
					<DropdownOuter
						onMouseLeave={() => {
							setDrop(false);
							setMenuColor("#2468f6");
						}}
						onMouseEnter={() => {
							setDrop(true);
							setMenuColor("#2455f6");
						}}
						x={menuRef?.current?.getBoundingClientRect().x}
					>
						<Dropdown>
							{keys?.map((x) => (
								<DropdownItem
									key={x}
									onClick={() => {
										history.push(`/subject/${x}`);
										setDrop(false);
									}}
								>
									{x}
								</DropdownItem>
							))}
						</Dropdown>
					</DropdownOuter>
				)} */}
				<NameLogo>
					<Link to="/home">
						<Logo src={require("../assets/console-blue.png").default} />
					</Link>
					<Link to="/home" style={{ textDecoration: "none" }}>
						<Name>ClassConsole</Name>
					</Link>
				</NameLogo>
				<Buttons>
					{!loading && currentUser ? (
						<User>
							<AccountCircle
								height={40}
								setUserDrop={setUserDrop}
								userDrop={userDrop}
								color="#d3d3d3"
								hoverColor="white"
							></AccountCircle>
						</User>
					) : (
						<>
							<StyledButton
								variant="link"
								onClick={() => {
									setRecShow(true);
								}}
							>
								+ Resources
							</StyledButton>
							<StyledButton
								variant="link"
								onClick={() => {
									setLoginShow(true);
								}}
							>
								Log In
							</StyledButton>
							<StyledButton
								variant="link"
								onClick={() => {
									setSignupShow(true);
								}}
							>
								Sign Up
							</StyledButton>
						</>
					)}
					{loading && "Loading..."}
					{userDrop && (
						<>
							<UserDropdownOuter>
								<UserDropdown>
									<UserDropdownItem to="/profile">
										<AccountCircle
											height={60}
											setUserDrop={setUserDrop}
											color="black"
											marginRight={20}
										></AccountCircle>

										{currentUser?.displayName
											? currentUser.displayName
											: "User Name"}
									</UserDropdownItem>
									<UserDropdownItem to="/profile">Profile</UserDropdownItem>
									{/* <UserDropdownItem to="/dashboard">Dashboard</UserDropdownItem> */}
									<UserDropdownItem
										onClick={() => {
											setRecShow(true);
										}}
									>
										+ Resources
									</UserDropdownItem>
									<UserDropdownItem onClick={handleLogout}>
										Logout
									</UserDropdownItem>
								</UserDropdown>
							</UserDropdownOuter>
						</>
					)}
				</Buttons>
			</Main>
			<Modal
				show={loginShow}
				onHide={() => {
					setLoginShow(false);
				}}
				centered
			>
				<Modal.Body>
					<Login setSignupShow={setSignupShow} setLoginShow={setLoginShow} />
				</Modal.Body>
			</Modal>
			<Modal
				show={signupShow}
				onHide={() => {
					setSignupShow(false);
				}}
				centered
			>
				<Modal.Body>
					<Signup setSignupShow={setSignupShow} setLoginShow={setLoginShow} />
				</Modal.Body>
			</Modal>
			<Modal
				show={recShow}
				onHide={() => {
					setRecShow(false);
				}}
				centered
			>
				<Modal.Body>
					<SubmitClass setRecShow={setRecShow} />
				</Modal.Body>
			</Modal>
		</>
	);
}
