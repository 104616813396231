import React from "react";
import { useParams } from "react-router-dom";

import { useData } from "../contexts/DataContext";

export default function ClassPage() {
	const { itemId } = useParams();
	const { getItem } = useData();
	const data = getItem(itemId);
	return (
		<>
			<pre>
				{JSON.stringify(data, null ,"    ")}
			</pre>
		</>
	);
}
