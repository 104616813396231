import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { Modal, Button, Form, FormControl, Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Navbar from "../components/Navbar";
import Subject from "../components/Subject";
import ClassCard from "../components/ClassCard";
import Bottom from "../components/Bottom";
import { useData } from "../contexts/DataContext";
import SearchBar from "../components/SearchBar";
import Login from "../components/Login";
import Signup from "../components/Signup";

const Main = styled.div`
	width: 100%;
	height: fit-content;
	padding-top: 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
`;

const Search = styled.div`
	width: 100%;
	height: 500px;
	@media (max-width: 500px) {
		height: 250px;
	}
	border-bottom: 1px rgb(235, 235, 235) solid;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Popular = styled.div`
	position: relative;
	top: 26%;
	@media (max-width: 500px) {
		top: 19%;
		padding-left: 20px;
	}
	@media (max-width: 500px) {
		top: 18%;
	}
	width: 100%;
	padding-left: 50px;
	font-size: 21px;
	font-weight: 600;
	font-family: "Montserrat", sans-serif;
`;

const About = styled.div`
	height: fit-content;
	border: 1px rgb(235, 235, 235) solid;
	background-color: rgb(241, 242, 247);
	padding: 20px 25px;
	@media (max-width: 425px) {
		display: none;
	}
`;

const Content = styled.div`
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
	background-color: white;
	width: 100%;
`;

const Title = styled.div`
	font-size: 30px;
	font-family: "Anton", sans-serif;
	color: black;
`;

const Description = styled.div`
	font-family: "Open Sans", sans-serif;
	line-height: 1.7;
	font-size: 18px;
	margin-top: 10px;
	font-weight: 300;
`;

const CTA = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 25px;
	margin-bottom: 30px;
	@media (max-width: 710px) {
		font-size: 20px;
	}
	@media (max-width: 500px) {
		font-size: 16px;
		margin-bottom: 20px;
	}
	@media (max-width: 400px) {
		font-size: 15px;
		margin-bottom: 20px;
	}
`;

const SignUp = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: #2468f6;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 25px;
	width: fit-content;
	padding: 0;
	&:hover {
		text-decoration: underline;
	}
	@media (max-width: 710px) {
		font-size: 20px;
	}
	@media (max-width: 500px) {
		font-size: 16px;
	}
	@media (max-width: 400px) {
		font-size: 15px;
	}
`;

const Logo = styled.img`
	width: 80px;
	padding-right: 20px;
	margin-bottom: 20px;
`;

const Or = styled.div`
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 18px;
`;

const Slogan = styled.div`
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 5px;
	@media (max-width: 710px) {
		font-size: 25px;
	}
	@media (max-width: 500px) {
		font-size: 20px;
	}
	@media (max-width: 400px) {
		font-size: 18px;
	}
`;

export default function Home() {
	const [loginShow, setLoginShow] = useState(false);
	const [signupShow, setSignupShow] = useState(false);
	const { homeData } = useData();
	const keys = Object.keys(homeData);
	const [source, setSource] = useState();
	const [children, setChildren] = useState();
	const [width, setWidth] = useState(window.innerWidth);
	console.log(source);
	useEffect(() => {
		var sourceType = {};
		keys.map((k) => {
			sourceType[k] = "Online Course";
		});
		setSource(sourceType);
	}, [homeData]);

	useEffect(() => {
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth);
		});
		return () =>
			window.removeEventListener("resize", () => {
				setWidth(window.innerWidth);
			});
	}, []);

	function returnClasses(lang) {
		if (source) {
			return homeData[lang].filter(
				e => e.Source == source[lang]
			).map((e) => <ClassCard {...e} Language={lang} />);
		}
	}

	return (
		<>
			<Main>
				<Content>
					<Navbar />
					{/* <About>
						<Title>What is Flossy Pork?</Title>
						<Description>
							In ancient China, students give their beloved teachers flossy pork
							or pork jerky as their tuition fee to show their gratitude. This
							culture originated from Confucius, the philosopher, the "Holy
							Master" of teaching. He accepted flossy pork due to his students'
							inabilities to pay their tuition in cash.
						</Description>
						<Description>
							Here at Flossy Pork, we help you find the best learning materials
							possible for <strong>you and you only.</strong> We personalize
							your interests and past learning situations to find the best
							matching course for you, including{" "}
							<strong>books, online courses, and real-life classes.</strong>
						</Description>
						
					</About> */}
					<Search>
						<Slogan>A Better Way to Learn Coding</Slogan>
						<CTA>
							<SignUp
								onClick={() => {
									setSignupShow(true);
								}}
							>
								Sign Up&nbsp;
							</SignUp>
							to personalize your learning!
						</CTA>
						<SearchBar size={width > 720 ? "lg" : ""} />
						<Popular>Popular Subjects</Popular>
					</Search>
					{/* <CTA>Customize your own learning path!</CTA> */}
					{keys.map((lang) => (
						<Subject
							Class={lang}
							id={lang}
							setSource={setSource}
							source={source}
						>
							{returnClasses(lang)}
						</Subject>
					))}
					<Bottom></Bottom>
				</Content>
			</Main>
			<Modal
				show={loginShow}
				onHide={() => {
					setLoginShow(false);
				}}
				centered
			>
				<Modal.Body>
					<Login setSignupShow={setSignupShow} setLoginShow={setLoginShow} />
				</Modal.Body>
			</Modal>
			<Modal
				show={signupShow}
				onHide={() => {
					setSignupShow(false);
				}}
				centered
			>
				<Modal.Body>
					<Signup setSignupShow={setSignupShow} setLoginShow={setLoginShow} />
				</Modal.Body>
			</Modal>
		</>
	);
}
